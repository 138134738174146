<template>
  <div>
    <a-form-model
      ref="banClientForm"
      :model="form"
      :rules="formRules"
    >
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="ban_hours" label="Период блокировки" prop="ban_hours">
            <a-radio-group v-model="form.ban_hours" button-style="solid" class="width-100p" style="width: 100%">
              <a-radio-button :value="12">
                12ч.
              </a-radio-button>
              <a-radio-button :value="24">
                Сутки
              </a-radio-button>
              <a-radio-button :value="168">
                Неделя
              </a-radio-button>
              <a-radio-button :value="720">
                Месяц
              </a-radio-button>
              <a-radio-button :value="86400">
                Навсегда
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="ban_desc" label="Комментарий" prop="ban_desc">
            <a-textarea
              v-model="form.ban_desc"
              placeholder="Причина блокировки"
              :auto-size="{ minRows: 4, maxRows: 6 }"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row>
      <a-col :span="24" class="text-right">
        <div class="text-right mt-3">
          <a-button type="danger" @click="onSubmit" :loading="submitLoading">
           Заблокировать
          </a-button>
          <a-button style="margin-left: 1rem;" @click="closeModal">
            Отмена
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'banClientModal',
  props: ['clientInfo'],
  data() {
    return {
      submitLoading: false,
      form: {
        ban_desc: '',
        ban_hours: 24,
      },
      formRules: {
        ban_desc: [{ required: true, message: 'Укажите причину блокировки', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record) {
      this.$emit('submitModalFunction', record)
    },
    onSubmit() {
      this.$refs.banClientForm.validate(valid => {
        if (valid) {
          this.submit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.banClientForm.resetFields()
    },
    async submit() {
      this.submitLoading = true

      return ApiService.client.banClient(this.clientInfo.id, this.form).then((response) => {
        this.$notification.success({
          message: 'Клиент заблокировн',
          description: `до ${this.$moment.parseZone(response?.ban?.unban_at).format('DD.MM.YY HH:mm')}`,
        })
        this.submitLoading = false
        this.submitModal(response)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при блокировке',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
  },
}
</script>

<style scoped>
</style>
