<template>
  <div class="clients-content">
    <!--    <a-row>-->
    <!--      <a-col :span="16">-->
    <!--        <h4 class="text-primary"><font-awesome-icon icon="file-invoice-dollar" /> Счета</h4>-->
    <!--      </a-col>-->
    <!--      <a-col :span="8" class="text-right">-->
    <!--        <a-button :loading="transactionsLoading" @click="getTransactions"><a-icon type="reload" /> Обновить</a-button>-->
    <!--      </a-col>-->
    <!--    </a-row>-->
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <span class="h5 mb-0"><font-awesome-icon icon="user-friends"
                                                 class="mt-1 mr-1 font-size-24"/> {{ $t('common.clients') }}</span>
      </div>
      <div class="d-flex mb-3">
        <div>
          <a-select v-model="projectId" :placeholder="$t('common.project')" class="width-200"
                    @change="handleProjectChange">
            <a-select-option :value="0">
              [{{ $t('pre.all') }} {{ $t('common.projects') }}]
            </a-select-option>
            <a-select-option v-for="project in projectList" :key="project.id">
              <a-badge status="success" v-if="project.is_active"/>
              <a-badge color="grey" v-else/>
              {{ project.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-select v-model="currencyId" :placeholder="$t('common.currency')" class="width-200"
                    @change="handleCurrencyChange">
            <a-select-option :value="0">
              [{{ $t('pre.all') }} {{ $t('common.currencies') }}]
            </a-select-option>
            <a-select-option v-for="currency in currencies" :key="currency.id">
              <a-badge status="success" v-if="currency.is_active"/>
              <a-badge color="grey" v-else/>
              {{ currency.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-auto">
          <a-input-search
            v-model="search"
            :placeholder="$t('placeholders.enterClientSearch')"
            style="width: 280px"
            allowClear
            @search="searchByInput"
          >
          </a-input-search>
        </div>
      </div>
      <a-table
        :columns="invoiceColumns"
        :dataSource="clientsData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="clientsTableLoading"
        @change="handleTableChange"
        :rowClassName="handleRowClasses"
      >
        <template v-slot:login="login, record">
          <span v-if="login">
            <div class="d-flex">
              <div>
                <span class="d-block text-primary small">
                  id: {{ record.id }}
                </span>
                <span v-if="record.parent_related" class="d-block text-gray-5 small">
                  <img v-if="record.parent_related.project.logo_url" :src="record.parent_related.project.logo_url"
                       width="14"/>
                  {{ record.parent_related.id }}
                </span>
                <span v-if="record.short_name" class="d-block text-gray-5 small">
                  {{ record.short_name }}
                </span>
              </div>
              <div class="ml-auto mt-2 pt-1">
                <span v-if="record.ban" class="flex">
                  <span class="mt-1">
                    <a-tooltip
                      :title="`⚠ ${$t('common.client')} ${$t('common.banned')}\n ${$moment.parseZone(record.ban.ban_at).format('DD.MM.YY HH:mm')}\n- ${$moment(record.ban.unban_at).format('DD.MM.YY HH:mm')}`"
                      :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
                      <font-awesome-icon icon="ban" class="text-danger font-size-18"/>
                    </a-tooltip>
                  </span>
                  <span class="ml-1">
                    <a-tooltip :title="`${$t('common.comment')}:\n${record.ban.comment}`"
                               :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
                      <a href="javascript:" class="text-gray-5 font-size-12"
                         @click="clipboardHandle(record.ban.comment)">
<!--                        <font-awesome-icon icon="info"  />-->
                        <span v-if="record.ban.comment">
                          {{
                            record.ban.comment.length > 24 ? `${record.ban.comment.substr(0, 24)}..` : record.ban.comment
                          }}
                        </span>
                      </a>
                    </a-tooltip>
                  </span>
                </span>
              </div>
            </div>
          </span>
        </template>
        <template v-slot:altId="altId, record">
          <!--          <span v-if="extId">-->
          <!--            {{ extId }}-->
          <!--          </span>-->
          <span v-if="altId" class="d-block">
            {{ altId }}
            <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right" class="">
              <a href="javascript:"><a-icon type="copy" @click="clipboardHandle(altId)"/></a>
            </a-tooltip>
            <span class="d-block mt-1 small">
              <span v-if="record.phone" class="text-gray-5 font-weight-normal">
                <span v-if="record.project.is_base === false" class="unbind_phone_btn">
                  <a-tooltip :title="'Unbind phone number'" placement="bottom">
                    <a-popconfirm
                      :title="`Sure to unbind phone number ?`"
                      @confirm="unbindPhoneNumber(record.id)"
                    >
                      <a href="javascript:" class="text-warning"><a-icon type="disconnect" /></a>
                    </a-popconfirm>
                  </a-tooltip>
                </span>
                {{ record.phone }}
                <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right">
                  <a href="javascript:" class="text-gray-5"><a-icon type="copy" @click="clipboardHandle(record.phone)"/></a>
                </a-tooltip>
              </span>
              <span v-else class="text-gray-4"><a-icon type="phone"/> unavailable</span>
            </span>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:project="project, record">
          <span v-if="project">
            <span v-if="project.logo_url">
              <img :src="project.logo_url" width="18"/>
            </span>
            {{ project.name }}
          </span>
          <span v-else>-</span>
          <span class="d-block mt-1 small text-gray-5 " v-if="record.currency">
            {{ record.currency.name }}
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:balance="balance, record">
          <span v-if="balance">
            <a-tag class="font-size-16" @click="showBalanceModal(record)"><a href="javascript:">{{
                balance.amount
              }} $</a></a-tag>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:payments="payment_sum, record">
          <span v-if="payment_sum && record.payment_counter.all > 0" class="small">
            <span>
              <a-tooltip :title="$t('tooltips.succeededPayments')" placement="bottom">
                <a-badge status="success"/>
                <span>
                  {{ payment_sum.buy.confirmed_usd }} $ <sup v-if="record.payment_counter.buy">{{ record.payment_counter.buy.confirmed }}</sup>
                </span>
              </a-tooltip>
            </span>
            <span class="d-block text-gray-5">
              <a-tooltip :title="$t('tooltips.allPayments')" placement="bottom">
                <a-badge color="grey"/>
                <span>
                  {{ payment_sum.buy.all_usd }} $ <sup>{{ record.payment_counter.buy.accepted }}</sup>
                </span>
              </a-tooltip>
            </span>
          </span>
          <span v-else>-</span>
          <div v-if="record.trust" class="small mt-2 text-gray-5">
            <div class="d-flex">
              <a-tooltip title="Trust level of buy-orders" placement="bottom">
                <span class="d-block">
                  <a-icon type="file-protect" /><sup>B</sup> {{ record.trust.buy_level }}%
                </span>
              </a-tooltip>
              <a-tooltip title="Trust level of sell-orders" placement="bottom">
                <span class="d-block ml-2">
                  <a-icon type="file-protect" /><sup>S</sup> {{ record.trust.sell_level }}%
                </span>
              </a-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:extId="extId, record">
          <!--          <span v-if="extId">-->
          <!--            {{ extId }}-->
          <!--          </span>-->
          <span v-if="extId" class="d-block small">
            <a-tooltip :title="extId" placement="bottom" class="">
              {{ (extId.length > 10) ? extId.substr(0, 10) + '..' : extId }}
            </a-tooltip>
            <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right" class="">
              <a href="javascript:"><a-icon type="copy" @click="clipboardHandle(extId)"/></a>
            </a-tooltip>
          </span>
          <span v-else>-</span>
          <span v-if="record.login" class="d-block mt-1 small text-gray-5">
            <a-tooltip :title="record.login" placement="bottom" class="">
              {{ (record.login.length > 10) ? record.login.substr(0, 10) + '..' : record.login }}
            </a-tooltip>
            <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right">
              <a href="javascript:" class="text-gray-5"><a-icon type="copy" @click="clipboardHandle(record.login)"/></a>
            </a-tooltip>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:created_at="created_at">
          <span v-if="created_at">
            {{ $moment.parseZone(created_at).format('DD.MM.YY') }}
            <span class="small d-block text-gray-5"><a-icon
              type="clock-circle"/> {{ $moment.parseZone(created_at).format('HH:mm') }}</span>
          </span>
        </template>
        <!--        <template v-slot:last_active="last_active">-->
        <!--          <span v-if="last_active">-->
        <!--            {{ $moment(last_active).format('DD.MM.YY') }}-->
        <!--            <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment(last_active).format('HH:mm') }}</span>-->
        <!--          </span>-->
        <!--        </template>-->
        <template v-slot:accessibility="text, record">
          <div class="accessibility" @click="showSettingsModal(record)">
            <i class="icon fe fe-settings font-size-16 text-primary"></i>
            <div class="accessibility-tags">
              <a-tag color="orange" v-if="record.settings.suspicion">
                Suspected
              </a-tag>
              <a-tag color="red" v-if="record.settings.is_banned">
                Banned
              </a-tag>
            </div>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal v-model="balanceModal"
             :destroyOnClose="true"
             :title="$t('common.balance') + ' for ' + (clientObj ? clientObj.login : '')"
             :footer="null"
             class="ban-client-modal"
             width="720px"
    >
      <client-balance-modal
        :balance="balanceObj"
        @closeModalFunction="closeBalanceModal"
        @balanceChangedFunction="balanceChanged"
      />
    </a-modal>
    <client-settings-modal
      ref="client-settings-modal"
      @update="updateClient"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import clientBalanceModal from '@/views/clients/balance/clientBalanceModal.vue'
import moment from 'moment-timezone'
import ApiService from '@/services/api/api.service'
import ClientSettingsModal from './ClientSettingsModal.vue'

const invoiceColumns = [
  {
    title: 'Client',
    dataIndex: 'login',
    key: 'login',
    width: '10%',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'AltID, Phone',
    dataIndex: 'alt_id',
    key: 'alt_id',
    width: '14%',
    scopedSlots: { customRender: 'altId' },
  },
  {
    title: 'Project, Currency',
    dataIndex: 'project',
    key: 'project',
    width: '12%',
    scopedSlots: { customRender: 'project' },
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    width: '8%',
    scopedSlots: { customRender: 'balance' },
  },
  {
    title: 'Orders',
    dataIndex: 'payment_sum',
    key: 'payment_sum',
    width: '12%',
    scopedSlots: { customRender: 'payments' },
  },
  {
    title: 'extID, login',
    dataIndex: 'ext_id',
    key: 'ext_id',
    width: '12%',
    scopedSlots: { customRender: 'extId' },
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '8%',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Accessibility',
    dataIndex: 'accessibility',
    width: '8%',
    key: 'accessibility',
    scopedSlots: { customRender: 'accessibility' },
  },
]

export default {
  name: 'index',
  components: {
    clientBalanceModal,
    ClientSettingsModal,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['projectList', 'currencies']),
  },
  data() {
    return {
      clientsData: [],
      balanceModal: false,
      clientObj: undefined,
      balanceObj: undefined,
      invoiceColumns,
      moment,
      clientLoading: false,
      clientsTableLoading: false,
      unbanLoading: false,
      pagination: { pageSize: 20 },
      search: '',
      projectId: 0,
      currencyId: 0,
    }
  },
  mounted() {
    this.refreshList()
  },
  methods: {
    handleRowClasses(record, index) {
      let classes = ''
      if (record.settings.is_banned) classes += ' client_banned'
      if (record.settings.suspicion) classes += ' client_suspicion'
      return classes
    },
    showSettingsModal(client) {
      const idx = this.clientsData.findIndex((obj) => obj.id === client.id)
      this.$refs['client-settings-modal'].isOpened = true
      this.$refs['client-settings-modal'].client = this.clientsData[idx]
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        search: this.search,
        project_id: this.projectId,
        currency_id: this.currencyId,
      })
    },
    handleProjectChange(e) {
      this.pagination.current = 1
      this.refreshList()
    },
    updateClient(client) {
      const idx = this.clientsData.findIndex((obj) => obj.id === client.id)
      if (idx !== -1) {
        this.$set(this.clientsData, idx, client)
      }
    },
    handleCurrencyChange() {
      this.pagination.current = 1
      this.refreshList()
    },
    async fetch(params = {}) {
      console.log('params:', params)
      this.clientsTableLoading = true
      const reqData = { results: 20, ...params }
      return ApiService.client.getClients(reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.clientsTableLoading = false
        this.clientsData = response.data.data
        this.pagination = pagination
        console.log(this.clientsData)
      }).catch(error => {
        console.log(error)
        this.clientsTableLoading = false
      })
    },
    async refreshList() {
      return this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        search: this.search,
        project_id: this.projectId,
        currency_id: this.currencyId,
      })
    },
    async searchByInput(value) {
      // if (value === '') { this.searchValue =  }
      this.pagination.current = 1
      return this.refreshList()
    },
    showBalanceModal(client) {
      this.clientObj = client
      this.balanceObj = client.balance
      this.balanceModal = true
    },
    closeBalanceModal() {
      this.balanceModal = false
    },
    unbindPhoneNumber(clientId) {
      // console.log('unbind phone ' + clientId)
      return ApiService.client.unbindPhoneNumber(clientId).then(() => {
        const index = this.clientsData.findIndex(obj => obj.id === clientId)
        this.clientsData[index].phone = null
        this.$message.success('Succeeded')
      }).catch(error => {
        console.log(error)
        this.$message.error(`Can not unbind phone number. Error: ${error.message}`)
      })
    },
    submitBalanceOperation() {
      console.log('operation submitted')
    },
    balanceChanged(clientId, balanceAmount) {
      console.log('client balance changed', [clientId, balanceAmount])
      const ind = this.clientsData.findIndex((obj) => obj.id === clientId)
      this.clientsData[ind].balance.amount = balanceAmount
    },
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: `${v.slice(0, 16)}...`,
      })
    },
  },
}
</script>

<style lang="scss">
.clients-content {
  table {
    tr {

      &.client_suspicion {
        background-color: #fffaf1;
      }
      &.client_banned {
        background-color: #fff1f1;
      }
      .accessibility {
        cursor: pointer;
        display: flex;
        gap: 12px;
        align-items: center;
        i {
          margin-top: 2px;
        }
        .accessibility-tags {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .ant-tag {
            text-align: center;
          }
        }
      }
    }
  }
}

.client-status-dot .ant-badge-status-dot {
  width: 16px;
  height: 16px;
}
</style>
