<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-statistic
          :title="$t('common.balance')"
          :value="amount"
          :precision="2"
          suffix="$"
          :value-style="{ color: balanceColor }"
          style="margin-right: 50px"
        >
          <template #prefix>
            <!--        <a-icon type="arrow-up" />-->
          </template>
        </a-statistic>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-button type="button" @click="openNewOperationModal">
          <a-icon type="dollar" /> {{ $t('buttons.btnCreate') }} {{ $t('common.operation') }}
        </a-button>
      </a-col>
    </a-row>
    <div class="mt-3">
      <div class="pb-1">{{ $t('common.balance') }} {{ $t('common.history') }}:</div>
      <client-balance-operations-table :client-id="clientId" :update="toUpdateOperations" table-size="small" />
    </div>
    <a-modal v-model="newOperationModal"
             :destroyOnClose="true"
             :title="`${$t('buttons.btnNew')} ${$t('common.operation')}`"
             :footer="null"
             class="ban-client-modal"
             width="480px"
    >
      <new-client-balance-operation-modal
        :client-id="clientId"
        @closeModalFunction="closeNewOperationModal"
        @submitModalFunction="submitNewBalanceOperation" />
    </a-modal>
  </div>
</template>

<script>
import ApiService from '@/services/api/api.service'
import clientBalanceOperationsTable from '@/views/clients/balance/clientBalanceOperationsTable.vue'
import newClientBalanceOperationModal from '@/views/clients/balance/newClientBalanceOperationModal.vue'

export default {
  name: 'clientBalanceModal',
  props: ['balance'],
  components: {
    clientBalanceOperationsTable,
    newClientBalanceOperationModal,
  },
  computed: {
    balanceColor() {
      return this.amount >= 0.00 ? '#3f8600' : '#cf1322'
    },
  },
  data() {
    return {
      updateLoading: false,
      clientId: this.balance.client_id,
      amount: this.balance && this.balance.amount ? this.balance.amount : 0,
      operations: [],
      toUpdateOperations: 0,
      newOperationModal: false,
      formOperation: {
        type: 'deposit',
        amount: 0,
        comment: '',
      },
      formOperationRules: {
        type: [{ required: true, message: 'Тип операции обязателен', trigger: 'change' }],
        amount: [{ required: true, message: 'Сумма обязательна', trigger: 'change' }],
        comment: [{ maxLength: 5, message: 'Укажите причину блокировки', trigger: 'change' }],
      },
    }
  },
  methods: {
    openNewOperationModal() {
      this.newOperationModal = true
    },
    closeNewOperationModal() {
      this.newOperationModal = false
    },
    submitNewBalanceOperation(operation) {
      // --
      console.log('new operation submitted from modal', operation)
      if (operation.type === 'deposit') { this.amount = operation.prev_b + operation.amount }
      if (operation.type === 'withdraw') { this.amount = operation.prev_b - operation.amount }
      this.newOperationModal = false
      this.toUpdateOperations++
      this.balanceChanged()
    },
    async refreshBalance() {
      return ApiService.client.getClientBalance(this.clientId).then((response) => {
        this.amount = response.amount
        this.$message.success('Balance refreshed')
      }).catch(error => {
        console.log(error)
        this.$message.error('Unable refresh balance')
        this.submitLoading = false
      })
    },
    balanceChanged() {
      this.$emit('balanceChangedFunction', this.clientId, this.amount)
    },
  },
}
</script>

<style scoped>

</style>
