<template>
  <a-modal v-model="isOpened"
           :destroyOnClose="true"
           title="Settings"
           :footer="null"
           class="client-settings-modal"
           width="720px"
           height="480px"
           v-if="client && client.settings"
  >
    <div class="ban-block">
      <a-card>
        <p class="h5">Ban</p>
        <a-list item-layout="horizontal">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <div class="d-flex" :class="{'mb-2': client.settings.ban}">
                  <p class="mb-0 pr-2">Status: </p>
                  <a-switch :loading="banSwitcherIsLoading" @change="changeBanState"
                            :checked="client.settings.is_banned"/>
                </div>
                <template v-if="client.settings.ban">
                  <p class="mb-0">Ban date: <span class="small text-gray-5">{{
                      $moment.parseZone(client.settings.ban.ban_at).format('HH:mm:ss DD.MM.YYYY')
                    }}</span></p>
                  <p class="mb-0">Unban date: <span class="small text-gray-5">{{
                      $moment.parseZone(client.settings.ban.unban_at).format('HH:mm:ss DD.MM.YYYY')
                    }}</span></p>
                </template>
              </template>
            </a-list-item-meta>
          </a-list-item>
          <a-list-item class="pb-0" v-if="client.settings.ban">
            <a-list-item-meta>
              <template #title>
                <p class="mb-0">Comment: </p>
              </template>
              <template #description>
                <p class="mb-0">{{ client.settings.ban.comment ? client.settings.ban.comment : 'Empty' }}</p>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </a-card>
    </div>
    <div class="suspicion-block mt-2">
      <a-card>
        <p class="h5">Suspicion</p>
        <a-list item-layout="horizontal">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <div class="d-flex" :class="{'mb-2': client.settings.suspicion}">
                  <p class="mb-0 pr-2">Status: </p>
                  <a-switch :loading="suspicionSwitcherIsLoading" @change="changeSuspicionState"
                            :checked="client.settings.suspicion"/>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </a-card>
    </div>

    <a-modal
      v-model="banModal"
      :destroyOnClose="true"
      title=""
      :footer="null"
      class="ban-client-modal"
      width="480px"
    >
      <ban-client-modal
        :client-info="client"
        @submitModalFunction="submitBanModal"
        @closeModalFunction="closeBanModal"
      />
    </a-modal>
  </a-modal>
</template>
<script>
import banClientModal from '@/views/clients/banClientModal.vue'
import apiClient from '@/services/axios'
import ApiService from '@/services/api/api.service'

export default {
  components: {
    banClientModal,
  },
  data() {
    const isOpened = false
    const client = null
    const banSwitcherIsLoading = false
    const suspicionSwitcherIsLoading = false
    const banModal = false

    return {
      banModal,
      banSwitcherIsLoading,
      suspicionSwitcherIsLoading,
      isOpened,
      client,
    }
  },
  methods: {
    closeBanModal() {
      this.banModal = false
    },
    async changeBanState(toState) {
      if (toState === true) {
        this.banModal = true
      } else {
        await this.unBanClient()
      }
    },
    async changeSuspicionState() {
      try {
        this.suspicionSwitcherIsLoading = true
        const status = Number(!this.client.settings.suspicion)
        const response = await apiClient.patch(`/admin/clients/${this.client.id}/suspect/${status}`)
        this.client = response.data.data
        this.$notification.success({
          message: 'Success',
          description: '',
        })
        this.$emit('update', response.data.data)
      } catch (e) {
        this.$notification.error({
          message: 'Something went wrong',
          description: e.message,
        })
      } finally {
        this.suspicionSwitcherIsLoading = false
      }
    },
    submitBanModal(client) {
      this.client = client
      this.$emit('update', client)
      // const ind = this.clientsData.findIndex((obj) => obj.id === client.id)
      // this.clientsData[ind].ban = client.ban
      this.banModal = false
    },
    async unBanClient() {
      try {
        this.banSwitcherIsLoading = true
        const client = await ApiService.client.unBanClient(this.client.id)
        this.client = client
        this.$emit('update', client)
      } catch (e) {
        // this.$notification.success({
        //   message: 'Client unbanned',
        //   description: '',
        // })
        // const ind = this.clientsData.findIndex((obj) => obj.id === id)
        // this.clientsData[ind].ban = response.ban
        // this.unbanLoading = false
      } finally {
        this.banSwitcherIsLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
.client-settings-modal {
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
}
</style>
