<template>
  <div>
    <div v-if="title" class="font-size-14">{{ title }}</div>
    <a-table
      :columns="tableColumns"
      :data-source="tableData"
      :row-key="record => record.id"
      :loading="tableLoading"
      :pagination="pagination"
      @change="handleTableChange"
      :scroll="{ y: 340 }"
      :bordered="false"
      :size="tSize"
    >
      <template v-slot:subtype="model, record" :class="tSize === 'small' ? 'font-size-10' : 'font-size-14'">
        <span class="balance_operation_model_icon">
          <a-icon v-if="record.model === 'project_deposit'" type="api" class="text-primary" />
          <a-icon v-if="record.model === 'client_buy'" type="credit-card" class="text-success" />
          <a-icon v-if="record.model === 'client_sell'" type="credit-card" class="text-danger" />
          <span v-if="record.model === 'client_transfer_in'" class="text-success">
            <a-icon type="team" />
          </span>
          <span v-if="record.model === 'client_transfer_out'" class="text-danger">
            <a-icon type="team" />
          </span>
        </span>
        <span> {{ operationBeautyName(model, record.type) }}</span>
      </template>
      <template v-slot:modelId="modelId, record" :class="tSize === 'small' ? 'font-size-10' : 'font-size-14'">
        <span v-if="modelId && record.model !== 'admin_operation'" >{{ modelId }}</span>
        <span v-else class="text-gray-5">
          <span v-if="record.admin_name">[{{ record.admin_name }}]</span>
          <span v-else>[admin]</span>
        </span>
        <span v-if="record.comment && record.comment.length > 0" class="ml-1">
          <a href="javascript:" class="text-primary" @click="showComment(record.comment)"><a-icon type="message" /></a>
        </span>
      </template>
      <template v-slot:amount="amount, record" :class="tSize === 'small' ? 'font-size-10' : 'font-size-14'">
        <span v-if="record.type">
          <a-icon v-if="record.type === operationTypes.deposit" type="arrow-up" class="text-success" />
          <a-icon v-if="record.type === operationTypes.withdraw" type="arrow-down" class="text-danger" />
        </span>
        {{ amount }} $
      </template>
      <template v-slot:prevbal="prevbal" :class="tSize === 'small' ? 'font-size-10' : 'font-size-14'">
        {{ prevbal }} $
      </template>
      <template v-slot:created_at="created_at">
        {{ $moment(created_at).format('DD.MM.YY HH:mm') }}
        <!--                  <span class="small text-gray-5"><a-icon type="clock-circle" /> {{ $moment(charged_at).format('HH:mm') }}</span>-->
      </template>
    </a-table>
  </div>
</template>

<script>
import ApiService from '@/services/api/api.service'
import { Modal } from 'ant-design-vue'

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '72px',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Operation Type',
    dataIndex: 'model',
    key: 'model',
    width: '20%',
    scopedSlots: { customRender: 'subtype' },
  },
  {
    title: 'Operation #',
    dataIndex: 'model_id',
    key: 'model_id',
    width: '15%',
    scopedSlots: { customRender: 'modelId' },
  },
  {
    title: 'Sum',
    dataIndex: 'amount',
    key: 'amount',
    width: '15%',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Prev. amount',
    dataIndex: 'prev_b',
    key: 'prev_b',
    width: '15%',
    scopedSlots: { customRender: 'prevbal' },
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'right',
    width: '18%',
    scopedSlots: { customRender: 'created_at' },
  },
]
function operationBeautyName(model, type) {
  switch (model) {
    case 'project_deposit': return 'Project Deposit'
    case 'client_buy': return 'Buy'
    case 'client_sell': return 'Sell'
    case 'admin_operation': return 'Admin ' + type
    case 'client_transfer_in': return 'Transfer IN'
    case 'client_transfer_out': return 'Transfer OUT'
    default: return '-'
  }
}
export default {
  name: 'clientBalanceOperationsTable',
  props: ['clientId', 'tableSize', 'title', 'update'],
  watch: {
    update(ov, nv) {
      this.pagination.current = 1
      this.fetch()
    },
  },
  data() {
    return {
      operationTypes: { deposit: 'deposit', withdraw: 'withdraw' },
      tableData: [],
      tSize: this.tableSize ? this.tableSize : undefined,
      Modal,
      tableColumns,
      operationBeautyName,
      // psystemModal: false,
      tableLoading: false,
      pagination: { pageSize: 10, page: 1 },
    }
  },
  mounted () {
    this.fetch({ page: 1 })
  },
  methods: {
    async fetch(params = {}) {
      this.tableLoading = true
      const reqData = { results: this.pagination.pageSize, ...params }
      return ApiService.client.getClientBalanceOperations(this.clientId, reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.tableLoading = false
        this.tableData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.tableLoading = false
      })
    },
    async handleTableChange(pagination, filters, sorter) {
      // console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      return this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
      })
    },
    showComment(comment) {
      const h = this.$createElement
      this.Modal.info({
        title: 'Comment for operation',
        content: h('div', {}, [
          h('p', comment),
        ]),
        onOk() {},
      })
    },
  },
}
</script>

<style scoped>

</style>
